<template>
  <div>
    <div class="btnStop1" @click="huanQi()">唤起</div>
  </div>
</template>

<script>
import axios from "axios";
import { getLiveJumpUrl, loginLive, create, getMyLive } from "../../api/index";
import customProtocolCheck from "custom-protocol-check";
const origin = "http://192.168.5.45:7001";
const username = window.sessionStorage.getItem("userName");
const password = username;
const anchorNick = "小助手测试员";

// 链接为上方 genurl 生成的地址，或其他方案通过 getLiveJumpUrl 接口得到的地址
// const url = `auipusher://page/live-room?xxxxxx`;

export default {
  data() {
    return {
      url: null,
      token: null,
    };
  },
  created() {},
  mounted() {
    this.login();
  },
  methods: {
    async login() {
      let pramsLogin = {
        password: 123456,
        username: "18808080808",
      };
      const { data } = await loginLive(pramsLogin);
      if (data.code == 200) {
        console.info(data.token, "login");
        this.token = data.token;
      }
    },
    async genurl() {
      const token = this.token;

      const now = new Date();
      // 创建直播间，获取直播间 id
      const liveIdRes = await axios.post(
        `${origin}/api/platform/live/create`,
        {
          anchor: window.sessionStorage.getItem("userId"),
          anchor_nick: window.sessionStorage.getItem("userName"),

          extends: "",
          mode: 1,
          notice: "直播公告",
          title: `直播测试${now.getMonth() + 1}${now.getDate()}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // let obj = {
      //   anchor: window.sessionStorage.getItem("userId"),
      //   anchor_nick: window.sessionStorage.getItem("userName"),

      //   extends: "",
      //   mode: 0,
      //   notice: "直播公告",
      //   title: `直播测试${now.getMonth() + 1}${now.getDate()}`,
      // };
      // console.info(obj);
      // const liveIdRes = await create(obj);
      console.info(liveIdRes, "liveIdRes");
      const { id: liveId } = liveIdRes.data;

      const { data } = await axios.post(
        `${origin}/api/platform/live/getLiveJumpUrl`,
        {
          live_id: liveId,
          user_id: window.sessionStorage.getItem("userId"),
          user_name: window.sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // 最后调用 getLiveJumpUrl 获取开播链接
      // let prams = {
      //   live_id: liveId,
      //   user_id: window.sessionStorage.getItem("userId"),
      //   user_name: window.sessionStorage.getItem("userName"),
      // };

      // const { data } = await getLiveJumpUrl(prams);

      console.info(data.liveJumpUrl, "data000");

      return data.liveJumpUrl;
      // return urlRes.data.live_jump_url;
    },

    //创建直播间
    huanQi() {
      this.genurl().then((url) => {
        console.log("url ->000000000000000", url);
        customProtocolCheck(
          url,
          (res) => {
            // 呼起成功
            console.log("callClient->success! ");
          },
          (res) => {
            // 没有协议
            // 建议此处实现提示下载的弹窗
          }
        );
        this.url = url;
      });
      console.info();
      // let url = this.url;
    },
  },
};
</script>

<style scoped>
.prism-player {
  width: 100%;
  height: 800px;
  /* opacity: 0; */
  /* display: none; */
}

.btnStop {
  cursor: pointer;
  background: pink;
  position: absolute;
  bottom: 0;
}
</style>
